import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";
import { useAuth } from "../AuthContext";

function Header(props) {
  const navigate = useNavigate();

  const {  logout } = useAuth();

  const logouts = () => {
    localStorage.clear();
    logout();
    navigate("/");
  };
  const logoutHandler = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure you want to logout?",
      buttons: [
        {
          label: "Yes",
          onClick: () => logouts(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex tab_button">
            
          </div>
          <div className="d-flex align-items-center">
           
            <div className="dropdown ms-1 topbar-head-dropdown header-item">
              <div className="dropdown-menu dropdown-menu-end"></div>
            </div>
            <div className="dropdown ms-sm-3 header-item topbar-user">
              <button
                type="button"
                className="btn shadow-none"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  <img
                    className="rounded-circle header-profile-user"
                    src="../../assets/images/users/avatar-1.jpg"
                    alt="Header Avatar"
                  />
                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text"></span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <a
                  href="#"
                  className="dropdown-item"
                  onClick={() => logoutHandler()}
                >
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />
                  <span className="align-middle" data-key="t-logout">
                    Logout
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
